



























import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CrmAppBar extends Vue {
  get showExtension(): boolean {
    return !!(this.$slots.extension || [])[0];
  }

  // eslint-disable-next-line class-methods-use-this
  get appVersion(): string {
    return `${process.env?.VUE_APP_VERSION}`;
  }
}
